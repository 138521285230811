export const types = {
    FETCH_EPISODE: 'FETCH_EPISODE',
    SET_EPISODE: 'SET_EPISODE',
    MERGE_EPISODE: 'MERGE_EPISODE',
    MERGE_EPISODES: 'MERGE_EPISODES',
    SET_PRELOADED: 'SET_PRELOADED',
    PRELOAD_CHARACTERS: 'PRELOAD_CHARACTERS',
    PRELOAD_EPISODES: 'PRELOAD',
    SET_CHARACTER: 'SET_CHARACTER',
    MERGE_CHARACTERS: 'MERGE_CHARACTERS',
}
