<template>
    <div class="image-skeleton" />
</template>

<script>
export default {
    name: "ImageSkeleton"
}
</script>

<style lang="scss">
.image-skeleton {
    width: 100%;
    height: 100%;
    background-color: $grey-4;
    display: block;
    border-radius: 3px;
}

</style>
